/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

const list = [
	{
		name   : 'English',
		file   : 'en',
		locale : [ 'en', 'en-en' ]
	},
	{
		name   : 'Portuguese',
		file   : 'pt',
		locale : [ 'pt', 'pt-pt' ]
	}
];

export const detect = () =>
{
	const localeFull = (navigator.language ||
		(navigator as any).browserLanguage).toLowerCase();

	// const localeCountry = localeFull.split(/[-_]/)[0];

	// const localeRegion = localeFull.split(/[-_]/)[1] || null;

	return localeFull;
};

export const getList = () => list;

export interface ILocale {
  name: string;
  file: string;
  locale: string[];
  messages: any;
}

export const loadOne = (locale: string): ILocale =>
{
	let res: any = {};

	try
	{
		res = list.filter(
			// (item) => item.locale.includes(locale) || item.locale.includes(locale.split(/[-_]/)[0])
			(item) => item.locale.includes(locale)
		)[0];

		res.messages = require(`./translations/${res.file}`);
	}
	catch
	{

		res = list.filter((item) => item.locale.includes('pt'))[0];

		res.messages = require(`./translations/${res.file}`);
	}

	return res;
};
